<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="">
      <div class="d-flex mb-3">
        <b-input
          v-model="name"
          @input="deferredRequest(name)"
          @keyup.native.enter="searchTemplates(name)"
        />

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          title="Поиск"
          class="ml-2"
          @click="searchTemplates(name)"
        >
          <b-icon icon="search" />
        </b-button>
      </div>

      <div
        v-if="isLoading"
        class="d-flex justify-content-center w-100 mt-3"
      >
        <b-spinner
          size="sm"
          variant="primary"
        />
      </div>
      <template v-else>
        <div
          v-for="(value, key) in templates"
          :key="key"
          class="template-item"
        >
          <div class="template-item__title d-flex align-items-center mb-2 bg-white">
            <div
              class="d-flex justify-content-between align-items-center bg-primary rounded p-2 w-100"
            >
              <div class="template-item__text">
                {{ value }}
              </div>
            </div>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              title="Изменить"
              size="sm"
              class="ml-2 size-btns"
              @click="editAppealTemplate(key, value)"
            >
              <b-icon icon="pencil-square" />
            </b-button>
            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              title="Удалить"
              size="sm"
              class="ml-2 size-btns"
              @click="agreeRemoveTemplate(key)"
            >
              <b-icon icon="x" />
            </b-button>
            <b-button
              variant="success"
              :type="$const.PRIMARY_BUTTON"
              title="Выбрать"
              size="sm"
              class="ml-2 size-btns"
              @click="selectTemplate(value)"
            >
              <b-icon icon="check-circle" />
            </b-button>
          </div>
        </div>

        <div
          v-if="templates.length === 0"
          class="mt-1"
        >
          <span>Мы ничего не нашли, попробуйте изменить критерии поиска...</span>
        </div>
      </template>
    </div>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="createAppealTemplate"
      >
        Создать шаблон
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { appealsTemplatesChatService } from '@/services';
import { showCustomMessage } from '@/helpers/messages';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'AppealTemplatesChatModal',
  components: {
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Выберите шаблон',
    },
  },

  data() {
    return {
      isOpen: true,
      isLoading: false,
      isSaving: false,

      templates: [],

      name: '',
      take: 25,
      skip: 0,
      isActive: true,
      deferred: null,
    };
  },

  computed: {
    ...mapGetters({
      fetchAppealsTemplatesChatTrigger: 'AppealsTemplates/FETCH_TRIGGER_TEMPLATES_APPEALS_CHAT',
    }),
  },

  watch: {
    async fetchAppealsTemplatesChatTrigger() {
      await this.fetchTemplates();
    },
  },

  async mounted() {
    this.fetchTemplates();
  },

  methods: {
    onClose() {
      this.$emit('input', false);
    },
    deferredRequest(value) {
      clearTimeout(this.deferred);

      this.deferred = setTimeout(() => {
        this.searchTemplates(value);
      }, 1200);
    },
    async searchTemplates(value) {
      clearTimeout(this.deferred);
      this.isLoading = true;

      try {
        const { templates } = await appealsTemplatesChatService.getWithSearch(value, true);
        if (Object.keys(templates).length) {
          this.templates = this.transformValues(templates);
        } else {
          this.templates = '';
        }
      } catch (e) {
        showCustomMessage('error', 'Ошибка', '');
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    editAppealTemplate(key, value) {
      uiService.showModal(MODALS.APPEAL_TEMPLATE_EDIT_CHAT_MODAL, {
        name: 'AppealTemplatesEditChatModal',
        props: {
          templateData: { key, value },
          title: 'Редактирование шаблона',
        },
      });
    },
    createAppealTemplate() {
      uiService.showModal(MODALS.APPEAL_TEMPLATE_EDIT_CHAT_MODAL, {
        name: 'AppealTemplatesEditChatModal',
      });
    },
    selectTemplate(value) {
      this.$emit('onSelectAppealTemplate', value);
      this.onClose();
    },
    async fetchTemplates() {
      clearTimeout(this.deferred);
      this.isLoading = true;

      try {
        const { templates } = await appealsTemplatesChatService.getAll();
        if (templates) {
          this.templates = this.transformValues(templates);
        }
      } catch (e) {
        showCustomMessage('error', 'Ошибка', '');
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async removeTemplate(id) {
      try {
        await appealsTemplatesChatService.delete(id);
        this.$store.commit('AppealsTemplates/TOGGLE_FETCH_CHAT_APPEALS_TEMPLATES_TRIGGER');
      } catch (e) {
        console.error(e);
      }
    },
    agreeRemoveTemplate(id) {
      uiService.showModal(MODALS.AGREE_MODAL, {
        name: 'AgreeModal',
        props: {
          title: 'Удаление шаблона',
        },
        actions: {
          delete: async () => {
            try {
              await this.removeTemplate(id);
              showCustomMessage('success', 'Шаблон был удален', '');
            } catch (err) {
              console.log(err);
            }
          },
        },
      });
    },
    transformValues(obj) {
      Object.keys(obj).forEach((key) => {
        obj[key] = obj[key]
          .slice(1, -1)
          .replace(/\\n/g, '\n')
          .replace(/\\/g, ' ');
      });
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.template-item {
  position: relative;

  &:not(:first-child) {
    margin-top: 1.2rem;
  }

  &__title {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &::before {
      position: absolute;
      content: '';
      transform: translate(0, -100%);
      left: 0;
      top: 0;
      height: 17px;
      width: 100%;
      background-color: #fff;
      z-index: -1;
    }
  }

  &__text {
    word-break: break-word;
    color: white;
    white-space: pre-wrap;
  }
}

.size-btns {
  width: 40px;
  height: 40px;
}
</style>
